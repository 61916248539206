<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="3"
      >
        <statistics-card-with-images
          class="fill-height"
          :icon="ratingsOptions.icon"
          :chip-color="ratingsOptions.chipColor"
          :chip-text="ratingsOptions.chipText"
          :statistics="totalAmountList"
          :stat-title="ratingsOptions.statTitle"
        ></statistics-card-with-images>
      </v-col>
      <v-col
        cols="12"
        sm="9"
      >
        <v-card class="fill-height" id="subscriber-list">
          <v-card-title>Suchen</v-card-title>
          <v-card-text>
            <div class="d-flex align-center pb-5">
              <v-text-field
                v-model="filter.search"
                single-line
                dense
                outlined
                hide-details
                clearable
                placeholder="Empfänger suchen"
                class="subscriber-list-search me-3"
              ></v-text-field>
              <v-select
                v-model="filter.idNipKanal"
                :items="filterChannelOptions"
                multiple
                hide-details="auto"
                outlined
                dense
                item-text="displayName"
                item-value="idNipKanal"
                label="Auswahl Kanäle"
                class="subscriber-list-idNipKanal me-3"
              ></v-select>
              <v-select
                v-model="filter.geschlecht"
                :items="filterGeschlechtOptions"
                multiple
                item-text="key"
                item-value="value"
                label="Geschlecht"
                outlined
                dense
                hide-details
                placeholder="Geschlecht"
                class="subscriber-list-geschlecht me-3"
              ></v-select>
              <v-select
                v-model="filter.land"
                :items="filterLandOptions"
                multiple
                item-text="key"
                item-value="value"
                label="Land"
                outlined
                dense
                hide-details
                placeholder="Land"
                class="subscriber-list-land me-3"
              ></v-select>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="listSubscribers"
          :options.sync="options"
          :server-items-length="totalAmountList"
          :items-per-page="options.itemsPerPage"
          :loading="loading"
          :search="filter.search"
          class="text-no-wrap"
          no-data-text="Keine Einträge vorhanden"
          no-results-text="Ihre Suche ergab keinen Treffer"
          :footer-props="{
            itemsPerPageText: 'Anzahl pro Seite',
            itemsPerPageAllText: 'Alle',
            pageText: '{0}-{1} von {2}',
            itemsPerPageOptions: [5,10,25,50,-1]
          }"
        >
          <template
            v-slot:top
          >
            <v-dialog
              v-model="dialogDelete"
              width="500px"
            >
              <v-card>
                <v-card-title
                  class="text-h5"
                >
                  Möchten Sie den Empfänger <strong>{{ deleteItem.name }}</strong> wirklich löschen?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="deleteSubscriberConfirm"
                  >
                    Löschen
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="closeDelete"
                  >
                    Abbrechen
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template #[`item.idNipAbonnent`]="{item}">
            #{{ item.idNipAbonnent }}
          </template>
          <template #[`item.kundenKennung`]="{item}">
            {{ item.kundenKennung }}
          </template>
          <template #[`item.nameDisplay`]="{item}">
            {{ item.nameDisplay }}
          </template>
          <template #[`item.email`]="{item}">
            {{ item.email }}
          </template>
          <template #[`item.geschlecht`]="{item}">
            {{ item.geschlecht }}
          </template>
          <template #[`item.plz`]="{item}">
            {{ item.plz }}
          </template>
          <template #[`item.ort`]="{item}">
            {{ item.ort }}
          </template>
          <template #[`item.geburtstagDE`]="{item}">
            {{ item.geburtstagDE }}
          </template>
          <template #[`item.actions`]="{item}">
            <div class="">

              <v-tooltip
                bottom
              >
                <template #activator="{ on, attrs }">
                  <router-link :to="'/empfaenger/editieren/'+item.idNipAbonnent" >
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                    </v-btn>
                  </router-link>
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>

              <v-tooltip
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteSubscriber( item )"
                  >
                    <v-icon size="18">
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Löschen</span>
              </v-tooltip>

            </div>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline, mdiWeb,
  mdiPlus, mdiViewDashboardVariantOutline, mdiAccountGroup,
} from '@mdi/js'
import axios from "axios";
import {getCurrentInstance, ref} from "@vue/composition-api";
import StatisticsCardWithImages from "@core/components/statistics-card/StatisticsCardWithImages";

export default {
  name: 'subscriber-list',
  components:{
    StatisticsCardWithImages
  },
  setup() {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 4000;

    const ratingsOptions = {
      statTitle: 'Empfänger',
      chipText: 'Gesamt',
      chipColor: 'error',
      icon:mdiAccountGroup
    }

    return {
      vm,
      ratingsOptions
    }
  },
  data() {
    return {
      actions: ['Delete', 'Edit'],
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiWeb,
        mdiPlus,
      },
      filter: { search: '', idNlpKanal: [], geschlecht : [], land : [], },
      loading: false,
      totalAmountList: 0,
      filterGeschlechtOptions: [
        { value: 'm', key: 'Männlich' },
        { value: 'w', key: 'Weiblich' },
        { value: 'd', key: 'Divers' },
      ],
      filterLandOptions: [
        { value: 'de', key: 'Deutschland' },
        { value: 'at', key: 'Österreich' },
        { value: 'ch', key: 'Schweiz' },
      ],
      filterChannelOptions: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['idNipAbonnent'],
        sortDesc: [true],
      },
      headers: [
        {
          text: '#ID',
          align: 'start',
          value: 'idNipAbonnent',
        },
        {text: 'Kennung', value: 'kundenKennung'},
        {text: 'Name', value: 'nameDisplay'},
        {text: 'E-Mail', value: 'email'},
        {text: 'Geschlecht', value: 'geschlecht'},
        {text: 'PLZ', value: 'plz'},
        {text: 'Ort', value: 'ort'},
        {text: 'Geburtstag', value: 'geburtstagDE'},
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      listSubscribers: [],
      dialogDelete: false,
      deleteItem: {
        idNipAbonnent: 0,
        nameDisplay: '',
      },
    }
  },
  mounted() {
    // this.fetchSubscribers()
    this.fetchChannels()
  },
  watch: {
    options: {
      handler() {
        this.fetchSubscribers()
      },
    },
    filter: {
      handler() {
        this.fetchSubscribers()
      },
      deep: true,
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  methods: {
    fetchChannels() {
      axios.post('api/kanal/', {
        aktion: 'showOverviewChannels',
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.filterChannelOptions = response.data.channels || []
          } else {
            console.log(response.data.errors)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchSubscribers() {
      this.loading = true

      axios.post('api/empfaenger/', {
        aktion: 'showOverviewSubscribers',
        filter: this.filter,
        options: encodeURIComponent(JSON.stringify(this.options)),
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.listSubscribers = response.data.subscribers || []
            this.totalAmountList = parseInt(response.data.total) || 0
            this.loading = false
          } else {
            console.log(response.data.errors)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteSubscriber (item) {
      this.dialogDelete = true
      this.deleteItem = Object.assign({}, item)
    },
    deleteSubscriberConfirm () {
      axios.post('api/empfaenger/', {
        aktion: 'deleteSubscriber',
        idNipAbonnent: this.deleteItem.idNipAbonnent,
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchSubscribers()
        })
        .catch((error) => {
          console.log(error)
        })

      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
    },
  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal;
}
#subscriber-list {
  .subscriber-list-search {
    max-width: 25%;
  }
  .subscriber-list-idNipKanal {
    max-width: 25%;
  }
  .subscriber-list-geschlecht {
    max-width: 25%;
  }
  .subscriber-list-land {
    max-width: 25%;
  }
}
</style>
